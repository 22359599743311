import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-messenger-card',
  templateUrl: './messenger-card.component.html',
  styleUrls: ['./messenger-card.component.scss']
})
export class MessengerCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openNewSetupPopup(){
    alert('Not implemented');
  }

}
