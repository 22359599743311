import { UserSession } from './../entity/models/user-session';
import { ApiResponse } from './../entity/models/api-response';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<UserSession>;
  public currentUser: Observable<UserSession>;

  constructor(private http: HttpClient,
    private router: Router,
    public jwtHelperService: JwtHelperService) {

    this.currentUserSubject = new BehaviorSubject<UserSession>(JSON.parse(localStorage.getItem('session')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public getToken() {
    const sessionStr = localStorage.getItem('session');
    const session = JSON.parse(sessionStr);
    let token;
    if (session) {
      token = session.authToken;
    }
    return token
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    return !this.jwtHelperService.isTokenExpired(token);
  }

  login(formData: any) {
    return this.http.post(environment.BASE_URL + '/auth/login', formData).pipe(map((authResponse: any) => {
      const token = authResponse.token;
      this.storeToken(token);
      const session = new UserSession();
      session.authToken = token;
      this.currentUserSubject.next(session);
      console.log("authResponse ", authResponse);
      console.log("auth session ", this.currentUserSubject.value);
      return authResponse;
    }));
  }

  sessionOut() {
    localStorage.removeItem('session');
    this.router.navigate(['/login']);
  }


  signup(formData: any): Observable<any> {
    return this.http.post(environment.BASE_URL + '/auth/signup', formData);
  }

  storeToken(token: string) {
    localStorage.setItem('session', JSON.stringify({ authToken: token }));
  }

  getUserSession(): UserSession {
    return this.currentUserSubject.value;
  }

}
