<div class="v-frame" [ngStyle]="{'background-image': (thumbnail) ? 'url('+ thumbnail + ')':'none'}">
  <div class="video-info">
    <div class="title">{{name}}</div>
    <div class="duration">{{duration | duration}}</div>
  </div>
  <div class="item">
    <div class="play-btn" (click)="openModal(template)">
      <i class="material-icons">play_arrow</i>
    </div>
  </div>
  <div class="video-downloader">
    <div class="downloader">
      <div class="file-size">{{size | byteformatter}}</div>
      <div class="file-progress">
        <app-downloader [name]="'test'" [source]="videoSource"></app-downloader>
      </div>
    </div>
  </div>
</div>


<ng-template #template>
  <div class="video-model-wrapper">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body video-modal mh-100">
      <plyr class="mh-100" style="display: block;" plyrTitle="Video 1" [plyrPlaysInline]="true" [plyrSources]="videoSource"
        (plyrInit)="initPlaye($event)"></plyr>
    </div>
  </div>
</ng-template>
