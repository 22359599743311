import { AuthSocketService } from './../services/auth-socket.service';
import { endpoints } from './../entity/consts/api-endpoints';
import stringInject from 'stringinject';
import { ContactRequest } from './../entity/models/contact-request';
import { Attachment } from './../entity/models/attachment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { BaseMessage } from '../entity/models/base-message';
import { ChatArchiveRequest } from '../entity/models/chat-archive-request';


@Injectable({
  providedIn: 'root'
})
export class ChatViewService {

  constructor(private http: HttpClient, private authSocket: AuthSocketService) {

  }

  sendMessage(message: BaseMessage) {
    return this.http.post(environment.BASE_URL + '/message', message);
  }

  receiveMessage() {
    return this.authSocket
      .fromEvent("inmsg");
  }

  outboundMessageStatus(): Observable<BaseMessage> {
    return this.authSocket
      .fromEvent("msgstatus");
  }

  outboundMessageSID() {
    return this.authSocket
      .fromEvent("msgSID");
  }

  getMessages() {
    return this.http.get(environment.BASE_URL + '/getMessages').
      pipe(
        map((data: []) => {
          return data.map((messageItem: any) => {
            messageItem.attachment.map((attachment: any) => {
              if (attachment.playAttachment) {
                attachment.duration = attachment.playAttachment.duration;
              }
              return attachment;
            });
            return messageItem;
          });
        }), catchError(error => {
          return throwError('Get messages through api failed!');
        })
      );
  }

  searchMessages(searchText: string) {
    const postEndPoint = stringInject(endpoints.searchMessages, { searchText });
    return this.http.get(environment.BASE_URL + '/' + postEndPoint);
  }

  newChat(phone: string) {
    const contactRequest = new ContactRequest();
    contactRequest.phone = phone;
    return this.http.post(environment.NEW_API_URL + '/message/new-chat', contactRequest);
  }

  /**
   * Archive specific chat api service
   * @param conversationId 
   * @param archived 
   * @returns 
   */
  archiveChat(conversationId: number, archived: boolean) {
    const chatArchiveRequest = new ChatArchiveRequest();
    chatArchiveRequest.conversationId = conversationId;
    chatArchiveRequest.archived = archived;
    return this.http.post(environment.NEW_API_URL + '/' + endpoints.archiveChat, chatArchiveRequest);
  }

}
