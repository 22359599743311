import { TemplateService } from './../../services/template.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { Client } from 'src/app/entity/models/user';
import { ProfileEditComponent } from 'src/app/page/profile-edit/profile-edit.component';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-chat-starter',
  templateUrl: './chat-starter.component.html',
  styleUrls: ['./chat-starter.component.scss']
})
export class ChatStarterComponent implements OnInit {
  newChatForm: FormGroup;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  newChatSubmitted = false;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  @Output() sendWelcomeRequest: EventEmitter<string> = new EventEmitter();
  welcomeTemplate: string;
  constructor(private formBuilder: FormBuilder,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private templateService: TemplateService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.initForm();
    this.getWelcomeTemplate();
    this.showLoader();
  }

  showLoader(){
    setTimeout(() => {
      this.spinner.hide();
    }, 50000);
  }

  private initForm(): void {
    this.newChatForm = this.formBuilder.group({
      phone: [null, [Validators.required]],
      templateText: [null, [Validators.required]],
      templateId: [null, [Validators.required]]
    });
  }

  get formControls(): any {
    return this.newChatForm.controls;
  }

  async getWelcomeTemplate() {
    this.templateService.getWelcomeTemplate().subscribe((templatedata: any) => {
      if (templatedata.status) {
        this.welcomeTemplate = templatedata?.template?.content;
        this.newChatForm.patchValue({
          templateText: this.welcomeTemplate,
          templateId: templatedata?.template?.templateId
        });
        console.log(this.welcomeTemplate);
      }
    });
  }

  sendWelcome() {

    if (this.newChatForm.invalid) {
      console.log(this.newChatForm);
      this.toastr.error('Fill the form', 'Form');
      return;
    }
    this.sendWelcomeRequest.emit(this.newChatForm.value);
  }


  public newChat() {
    console.log('new chat');
    // this.newChatSubmitted = true;
    // console.log('form  data ', this.newChatForm);

    // if (this.newChatForm.invalid) {
    //   return;
    // }

    // const phoneData = this.newChatForm.get('phone').value;
    // // const phone = phoneData.number;
    // const phone = phoneData.e164Number;

    // console.log('phone data ', phone);

    // this.ChatService.newChat(phone).subscribe((res: ApiResponse) => {
    //   console.log('response ', res);
    //   if (res.status) {

    //     const contact = new Client();
    //     contact.conversationId = res.data.conversationId;
    //     // contact.firstname = '';
    //     contact.lastName = '';
    //     contact.chatNumber = phone;
    //     // contact.unreadCount = 0;
    //     // contact.lastUpTime = new Date();
    //     this.contacts.unshift(contact);

    //     console.log($('example ', '#exampleModalCenter'));
    //     $("#closecontact").click();

    //     this.selectUser(contact);

    //     this.toastr.success('Message sent successfully', 'Contact');
    //   } else {
    //     this.toastr.error('Messege sending Failed.', 'Contact');
    //   }
    // });


  }




}
