import { IntegrationService } from 'src/app/services/integration.service';
import { Component, OnInit } from '@angular/core';
import { INTEGRATION_STATUS } from 'src/app/entity/enums/intergration-status';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-api-card',
  templateUrl: './api-card.component.html',
  styleUrls: ['./api-card.component.scss']
})
export class ApiCardComponent implements OnInit {
  APIkey: string;

  constructor(private integrationService: IntegrationService, private clipboardService: ClipboardService) { }
  INTEGRATION_STATUS: typeof INTEGRATION_STATUS = INTEGRATION_STATUS;
  status: INTEGRATION_STATUS;
  ngOnInit(): void {
    this.getApiKey();
  }

  async getApiKey() {
    this.integrationService.getApiKey().subscribe(res => {
      this.status = res.status
      if (res.status === INTEGRATION_STATUS.INSTALLED) {
        this.APIkey = res.APIkey;
      }
    });
  }

  async generateApiKey() {
    this.integrationService.generateApiKey().subscribe(res => {
      this.status = res.status
      if (res.status === INTEGRATION_STATUS.INSTALLED) {
        this.APIkey = res.APIkey;
      }
    });
  }

  copyKey() {
    console.log('coping .. ', this.APIkey);
    this.clipboardService.copyFromContent(this.APIkey)
  }

}
