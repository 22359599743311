export enum TEMPLATE_NAMES {
  annual_boiler_service_1 = "Annual Boiler Service",
  eicr_1 = "EICR",
  cp12_1 = "CP12",
  landlord_and_homebuyers_pack = "Landlord & Homebuyers Pack",
  gas_leak_repairs = "Gas Leak Repairs",
  plumbing_leak_repairs = "Plumbing Leak Repairs",
  boiler_repair = "Boiler Repair",
  toilet_repair = "Toilet Repair ",
  electrical_one_off_repair = "Electrical One Off Repair",
  warm_air_boiler_repair = "Warm Air Boiler Repair",
  central_heating_powerflush_1 = "Central Heating Powerflush",
  shower_repair_concealed_shower_valves = "Shower Repair (Concealed Shower Valves)",
  kitchen_appliance_repairs = "Kitchen Appliance Repairs",
  silcone_resealing = "Silicone Resealing",
  epc_quote = "EPC Quote",
  pat_quote = "PAT Quote",
  budget_subject_to_a_site_survey = "Budget Subject To A Site Survey",
  estimate_by_time = "Estimate By Time",
  quote_from_photos = "Quote From Photos",
  appointment_process = "Appointment Process",
  specific_time_slot_qualification = "Specific Timeslot Qualification",
  provisional_appointment_deposit_request = "Provisional Appointment Deposit Request",
  resident_access = "Resident Access",
  response_for_request_for_a_breakdown = "Response For Request For A Breakdown",
  additional_information_needed_to_quote = "Additional Information Needed To Quote",
  additional_information_needed_to_proceed = "Additional Information Needed To Proceed",
  quote_need_more_time = "Quote Need More Time",
  fishing = "Fishing",
  compliance_request = "Compliance Request",
  close_application = "Close Application",
  close_enquiry = "Close Enquiry",
  opening_feedback_request_message = "Opening Feedback Request Message",
  four_or_five_star_reply = "4 Or 5 Star Reply",
  one_or_three_star_reply = "1 Or 3 Star Reply",
  recruitment_telephone_interview_invite_1 = "Recruitment Telephone Interview Invite",
  deposit_case_5 = "Deposit Payment Request",
  twentyfour_hour_limit_req_for_cust_reply_4 = "Hello, it has been 24 hours since you messaged us.",
  req_for_cust_reply = "Hello, can you just send me a reply message so we can continue to talk?",
  induction_meeting_invite = "Induction Meeting Invite",
  annual_warm_air_boiler_service = "Annual Warm Air Boiler Service",
  combined_annual_boiler_service = "Combined Annual Boiler Service & Gas Safety Check",
  requests_for_bespoke_quote_breakdowns = "Requests For Bespoke Quote Breakdowns",
  }  