import { NgxSpinnerService } from 'ngx-spinner';
import { BaseMessage } from './../../entity/models/base-message';
import { ProfileEditComponent } from './../../page/profile-edit/profile-edit.component';
import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { PaginationResultDto } from 'src/app/entity/models/dto/pagination-contact';
import { ContactsService } from 'src/app/services/contacts.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from 'src/app/services/user.service';
import { Client } from 'src/app/entity/models/user';
import * as _ from 'lodash';

@Component({
  selector: 'app-contact-manager',
  templateUrl: './contact-manager.component.html',
  styleUrls: ['./contact-manager.component.scss']
})
export class ContactManagerComponent implements OnInit {

  contacts: Client[] = [];
  contactsPerPage = 5;
  currentConactPage = 1;
  totalContacts;
  @Output() contactClicked: EventEmitter<number> = new EventEmitter();
  editContactModelReference: BsModalRef;
  contactSpinner = 'contactSpinner';
  @Input() contactDetails: Client;
  constructor(private contactService: ContactsService,
    private modalService: BsModalService,
    private clientService: UserService,
    private router: Router,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    this.loadContacts();

  }

  ngOnChanges(changes) {
    if (this.contactDetails) {
      this.currentConactPage = 1;
      this.contacts = [];
      this.loadContacts();
    }
    
  }

  public loadContacts() {
    this.spinner.show(this.contactSpinner);
    const lastUpTime = new Date();
    console.log('conversationPagex', this.currentConactPage);

    this.contactService.getContacts(this.currentConactPage).subscribe((conversationPage: PaginationResultDto<Client>) => {
      console.log('conversationPage ', conversationPage);

      this.totalContacts = conversationPage.totalCount;
      const resultCount = conversationPage.data.length;

      conversationPage.data.forEach((contact) => {
        const contactExist = false;
        // const contactExist = this.getContactByConversationId(conversation.conversationId);
        if (!contactExist) {
          this.contacts.push(contact);
          console.log('contacts ===', this.contacts);
        }
      });
    }, err => { }, () => {
      this.spinner.hide(this.contactSpinner);
    });

    this.currentConactPage++;
  }

  selectContact(contact: Client) {
    contact.unreadCount = 0;
    this.contactClicked.emit(contact.userId);
  }

  navigateToProfilePage(contact: Client) {
    this.openEditContactPopup(contact);
    return false;
  }

  openEditContactPopup(contact: Client) {
    console.log("Browser");
    this.editContactModelReference = this.modalService.show(ProfileEditComponent, {
      class: 'modal-lg',
      initialState: {
        contact: contact
      }
    });
    this.editContactModelReference.content.closeBtnName = 'Close';
    this.editContactModelReference.content.profileUpdated.subscribe((value) => {
      console.log("contact updated ", value);
      // this.messageTemplate = value;
      // this.sendMessage();
      this.editContactModelReference.hide();
    });
  }


  receiveMessage(message: BaseMessage, archivedContacts: Client[]) {
    const contact = this.contactExist(message.chatNumber, archivedContacts);
    if (contact) {
      contact.unreadCount++;
      this.sortConversation(contact);
      if (contact.archived) {
        this.addUnarchivedToContacts(contact); // without this Contacts tab will not show archived contact when message received to it
        contact.archived = false;
      }
    } else {
      this.clientService.getClientByChatNumber(message.chatNumber).subscribe(res => {
        this.displayConact(res);
      });
    }
  }

  sortConversation(contact: Client) {
    const contactIndex = this.contacts.findIndex(item => {
      return item.userId === contact.userId;
    });
    console.log('contactIndex', contactIndex);
    if (contactIndex > 0) {
      this.contacts.splice(contactIndex, 1);
      this.contacts.unshift(contact);
      console.log('after sort ', this.contacts);
    }
  }


  /**
   * Check whether received messages from already existing client
   * @param chatNumber 
   * @param archivedContacts 
   * @returns 
   */
  contactExist(chatNumber: string, archivedContacts: Client[]) {
    const contact = this.contacts.find(contact => {
      console.log(contact.chatNumber, chatNumber);
      return contact.chatNumber === chatNumber
    });

    const archivedContact = archivedContacts.find(contact => {
      console.log(contact.chatNumber, chatNumber);
      return contact.chatNumber === chatNumber
    });

    return (contact) ? contact : archivedContact;
  }

  displayConact(contact: Client) {
    console.log('contact [--', contact);
    this.contacts.unshift(contact);
  }

  /**
   * Show unarchived contact on contacs tab
   * @param contact 
   */
  addUnarchivedToContacts(contact: Client) {
    this.contacts.unshift(contact);
  }

}


