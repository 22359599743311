/**
 * Pipe to convert message date in to hours minutes format. ex: 07.28 pm
 */

import { Pipe, PipeTransform } from '@angular/core';
import { format, formatDistance, subDays } from 'date-fns';

@Pipe({
  name: 'messageDate'
})
export class MessagedatePipe implements PipeTransform {

  transform(date: Date): string {

    // return "";
    if (!date) {
      return '';
    }
    let formattedDate = '';
    try {
      formattedDate = format((new Date(date)), 'dd-MM-yyyy h:mm a');
    } catch (e) {

    }
    return formattedDate;
  }

}
