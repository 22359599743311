import { INTEGRATION_STATUS } from './../../entity/enums/intergration-status';
import { IntegrationService } from './../../services/integration.service';
import { WhatsappFormComponent } from './../../components/intergrations/whatsapp-form/whatsapp-form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { CHANNELS } from 'src/app/entity/enums/channels';

@Component({
  selector: 'app-channel-intergration',
  templateUrl: './channel-intergration.component.html',
  styleUrls: ['./channel-intergration.component.scss']
})
export class ChannelIntergrationComponent implements OnInit {

  ngOnInit(): void {

  }


}
