<div class="card p-3 mb-2">
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-row align-items-center">
      <div class="icon">
        <img src="./../../../assets/img/integrations/api.png" class="card-img" alt="...">
      </div>
      <div class="ms-2 c-details">
        <h6 class="mb-0 pl-2">API integration</h6>
      </div>
    </div>
    <div class="badge"> <span>Free</span> </div>
  </div>
  <div class="mt-5">
    <p>API</p>
    <div class="input-group mb-3">
      <input type="text" class="form-control" readonly aria-describedby="basic-addon2" [(ngModel)]="APIkey">
      <div class="input-group-append">
        <button class="btn btn-outline-warning" type="button" (click)="copyKey()">
          <i class="fas fa-copy"></i>
        </button>
      </div>
    </div>
    <button (click)="generateApiKey()" class="btn btn-primary">{{(APIkey?'Regenerate':'Generate')}}</button>
    <!-- <button *ngIf="INTEGRATION_STATUS.REQUESTED===status" class="btn btn-primary">Pending</button>
    <button *ngIf="INTEGRATION_STATUS.INSTALLED===status" class="btn btn-primary">
      Installed <i class="fas fa-pen"></i>
    </button> -->
  </div>
</div>
