import { DOCUMENT_TYPES } from "../../enums/document-types";

export class MessageContentMedia {
    documentType: DOCUMENT_TYPES
    thumbnail?: string;
    localURL?: string;
    cloudURL?: string;
    attachmentId?:number;
    duration?: number;
    text: string;
    size: number;
  name: any;
}
