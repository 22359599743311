import { TemplateManagerComponent } from './pages/template-manager/template-manager.component';
import { ChannelIntergrationComponent } from './pages/channel-intergration/channel-intergration.component';
import { ChatBoxComponent } from './chat-box/chat-box.component';
import { AuthGuardService } from './services/auth-guard.service';
import { TemplateEditorComponent } from './components/template-editor/template-editor.component';
import { ProfileEditComponent } from './page/profile-edit/profile-edit.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChatViewComponent } from './chat-view/chat-view.component';
import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [
  {
    path: 'chat',
    component: ChatViewComponent,
    canActivate: [AuthGuardService]
  },
  { path: 'dashboard-component', component: DashboardComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'profile/:profileId', component: ProfileEditComponent },
  { path: 'editor', component: TemplateEditorComponent },
  { path: 'template-manager', component: TemplateManagerComponent },
  { path: 'intergrations', component: ChannelIntergrationComponent },
  {
    path: 'chat',
    component: ChatViewComponent,
    canActivate: [AuthGuardService],
    children: [
      // { path: '',  redirectTo: '0',pathMatch: 'full'},
      { path: ':chatId', component: ChatBoxComponent },


    ]
  },
  // { path: 'chat-box/:chatId', component: ChatBoxComponent },
];

export function tokenGetter() {
  return localStorage.getItem("access_token");
}

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      relativeLinkResolution: 'legacy',
      paramsInheritanceStrategy: 'always'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
