import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  transform(content: String, term: string): string {
    const regex = new RegExp( term , "gi");
    console.log(content, term);
    return content.replace(regex, "<span class='search-highlight'>$&</span>");
  }

}
