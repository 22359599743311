<div class="requests">
  <div class="title">
    <h1>Setup Whatsapp</h1>
    <button type="button" class="btn" (click)="bsModalRef.hide()" data-dismiss="modal" aria-label="Close">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="content">
    <form [formGroup]="integrationForm" (ngSubmit)="submit()">
      <div class="form-group">
        <label for="user">Channel a name</label>
        <input type="text" class="form-control" id="user" formControlName="channelName"  [ngClass]="{ 'is-invalid': submitted && formControls.channelName.errors }">
      </div>
      <div class="form-group">
        <label for="user">Business number</label>
        <input type="text" class="form-control" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && formControls.phone.errors }">
      </div>
      <div class="form-group">
        <label for="welcome">Certificate:</label>
        <textarea class="text-control form-control" id="welcome" formControlName="certificate" [ngClass]="{ 'is-invalid': submitted && formControls.certificate.errors }"></textarea>
      </div>
      <button type="submit" class="btn button w-100">Submit</button>
    </form>
  </div>
</div>
