import { TemplateData } from './../../entity/models/template-data';
import { Template } from './../../entity/models/template';
import { TemplateService } from './../../services/template.service';
import { FormArray, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TemplateHelper } from '../../helpers/template-helper';

@Component({
  selector: 'app-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss']
})
export class TemplateEditorComponent implements OnInit {

  selectedTemplate: Template;
  templateRenderedText = "";
  userForm: FormGroup;
  splitWords = [];
  templates;
  @Output() passTemplate: EventEmitter<TemplateData> = new EventEmitter();
  windowOpen: boolean;
  templatePlaceholders = [];
  templatePlaceholdersNoBrackets = [];
  selectedTemplateId: number;
  templatesGroups: string[];

  constructor(private fb: FormBuilder, private templateService: TemplateService) {
    this.userForm = this.fb.group({
      template: [''],
      templateFields: this.fb.array([])
    });
  }

  ngOnInit() {
    this.initTemplate();
  }

  initTemplate() {
    this.resetTemplateControl();
    this.listneFieldChange();
    this.fetchTemplates();
  }

  buildTemplate() {
    this.resetTemplateControl();
    this.parseTemplate();
    this.generateFields();
    this.renderTemplate([]);
  }

  resetTemplateControl() {
    (this.userForm.get('templateFields') as FormArray).clear();
  }

  changeTemplate(template: Template) {
    this.selectedTemplate = template;
    console.log("template changed ", this.selectedTemplate);
    this.buildTemplate();
  }

  generateFields() {
    const fieldCount = this.getTemplateFieldsCount();
    for (let i = 0; i < fieldCount; i++) {
      let fieldValue = null;
      fieldValue = this.preFillTemplatePlaceholders(i);

      this.addField(fieldValue);
    }
  }

  getTemplateFieldsCount() {
    return this.splitWords.length - 1;
  }


  fetchTemplates() {
    this.templateService.getTemplates().subscribe((templates: Template[]) => {
      this.templates = templates;
      const templateGroups = this.templatesGroups ?? [];
      this.templates.forEach(function(a) {
        if (!templateGroups.includes(a.group)) {
          templateGroups.push(a.group);
        }

      });

      this.templatesGroups = templateGroups;
    });
  }

  parseTemplate() {
    // let curlyRegex = /{\{\d+\}\}/g;
    let curlyRegex = /\{{.*?\}}/igm;
    let newRegex = /({{[^{]*?)\w(?=\})}}/igm;
    let updatedNewRegex = /([^{]*?)\w(?=\})/igm;
    // let matches = this.templateText.match(curlyRegex);
    // console.log("matches ", matches);
    this.splitWords = this.selectedTemplate.content.split(curlyRegex);
    this.templatePlaceholders = this.selectedTemplate.content.match(newRegex);
    this.templatePlaceholdersNoBrackets = this.selectedTemplate.content.match(updatedNewRegex);
    this.selectedTemplateId = this.selectedTemplate.templateId;
    console.log("splitWords ", this.splitWords);
  }

  renderTemplate(values: Array<string>) {
    // this.templateRenderedText =
    let text = "";
    const wordLength = this.splitWords.length;
    const placeholdersLength = (this.templatePlaceholders) ? this.templatePlaceholders.length : 0;
    this.splitWords.forEach((word, i) => {
      // skip last iteration
      const isLast = (i === wordLength - 1);
      let value = values[i];
      let placeholder = "";
      if (i <= placeholdersLength-1) {
        placeholder = this.templatePlaceholders[i];
        if (values.length == 0) {
          value = this.preFillTemplatePlaceholders(i, values);
        }
      }
      text += word + ((value && !isLast) ? value : placeholder);
    });
    this.templateRenderedText = text;
    console.log("render ", text);
  }

  listneFieldChange() {
    this.userForm.controls['templateFields'].valueChanges.subscribe(value => {
      console.log('changes ', value);
      this.renderTemplate(value);
    });
  }

  addField(fieldValue: string): void {
    (this.userForm.get('templateFields') as FormArray).push(
      this.fb.control(fieldValue)
    );
  }

  removePhone(index) {
    (this.userForm.get('templateFields') as FormArray).removeAt(index);
  }

  getPhonesFormControls(): AbstractControl[] {
    return (<FormArray>this.userForm.get('templateFields')).controls
  }

  send() {
    if (this.userForm.invalid) {
      return;
    }
    const formData = this.userForm.value;
    const templateData=new TemplateData();
    console.log('form value ',this.userForm.value);
    templateData.renderedText=this.templateRenderedText;
    templateData.templateId=formData.template.templateId;
    templateData.text=formData.template.content;
    templateData.templateFields=formData.templateFields;
    console.log("template data ", templateData);
    this.passTemplate.emit(templateData);
  }

  sendTemplateData() {
    console.log("", this.getPhonesFormControls().values);
  }

  /**
   * Show values passed from flobot on relevant template fields/placeholders
   * @param index 
   * @param templateValues 
   * @returns 
   */
   preFillTemplatePlaceholders(index, templateValues: Array<string> = []) {
    let templateValue = "";
    switch (this.templatePlaceholdersNoBrackets[index]) {
      case "1":
        templateValue = (this.selectedTemplateId == 260) ? "50" : "";
        break;
      case "2":
        templateValue = (this.selectedTemplateId == 254) ? "125" : (this.selectedTemplateId == 256) ? "200" : (this.selectedTemplateId == 260) ? "70" : "";
        break;
      case "3":
        templateValue = (this.selectedTemplateId == 186) ? "35" : (this.selectedTemplateId == 254) ? "200" : (this.selectedTemplateId == 256) ? "300" : (this.selectedTemplateId == 257) ? "50" : (this.selectedTemplateId == 188 || this.selectedTemplateId == 189) ? "5" : "";
        break;
      case "4":
        templateValue = (this.selectedTemplateId == 254 || this.selectedTemplateId == 256) ? "65" : (this.selectedTemplateId == 190) ? "35" : "";
        break;
      case "5":
        templateValue = (this.selectedTemplateId == 190) ? "500" : (this.selectedTemplateId == 191) ? "35" : "";
        break;
      default:
        templateValue = (templateValues.length > 0) ? templateValues[index] : null;
        break;
    }

    return templateValue;
  }

  getTemplateName(name: string) {
    return TemplateHelper.getTemplateName(name);
  }

}
