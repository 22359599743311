import { HttpParamsTags } from './../entity/models/HttpParamsTags';
import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {


  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available
    const currentUser = this.authService.getUserSession();
    const noAuth=request?.params instanceof HttpParamsTags && request.params.param1;
    if (!noAuth && currentUser && currentUser.authToken) {
      console.log("auth interecpted ", currentUser);
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.authToken}`
        }
      });
    }
    request = request.clone();
    return next.handle(request);
  }
}
