import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'byteformatter'
})
export class ByteformatterPipe implements PipeTransform {

  transform(bytes: number): string {
    if (!bytes) {
      return '';
    }
    return this.formatBytes(bytes);
  }

  formatBytes(bytes, decimals = 2): string {
    /**
     * format bytes ex: 6791307 bytes -> 6.48 MB
     */

    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB']; // ignore too big sizes as whatsapp have filesize limitations
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

}
