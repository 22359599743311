import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-messanger-form',
  templateUrl: './messanger-form.component.html',
  styleUrls: ['./messanger-form.component.scss']
})
export class MessangerFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }



}
