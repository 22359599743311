import { TemplateFormComponent } from './components/template-form/template-form.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChatViewComponent } from './chat-view/chat-view.component';
import { SideBarMenuComponent } from './side-bar-menu/side-bar-menu.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { environment } from './../environments/environment';
import { LoaderComponent } from './shared/loader/loader.component';
import { MediaPreviewComponent } from './chat/media-preview/media-preview.component';
import { ToastrModule } from 'ngx-toastr';
import { LightboxModule } from 'ngx-lightbox';
import { AudioPlayerComponent } from './chat/audio-player/audio-player.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { VideoPlayerComponent } from './chat/video-player/video-player.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CircleProgressComponent } from './shared/circle-progress/circle-progress.component';
import { ChatItemComponent } from './chat/chat-item/chat-item.component';
import { PlyrModule } from 'ngx-plyr';
import { PdfViewerComponent } from './chat/pdf-viewer/pdf-viewer.component';
import { DownloaderComponent } from './shared/downloader/downloader.component';
import { ByteformatterPipe } from './pipes/byteformatter.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { MessagedatePipe } from './pipes/messagedate.pipe';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ContactComponent } from './components/contact/contact.component';
import { ReversePipe } from './pipes/reverse.pipe';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { ProfileEditComponent } from './page/profile-edit/profile-edit.component';
import { TemplateEditorComponent } from './components/template-editor/template-editor.component';
import { HighlightPipe } from './pipes/highlight.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { ImgFallbackModule } from 'ngx-img-fallback';
import { ImageCropperModule } from 'ngx-image-cropper';
import { JwtModule } from "@auth0/angular-jwt";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ChatBoxComponent } from './chat-box/chat-box.component';
import { ContactManagerComponent } from './components/contact-manager/contact-manager.component';
import { SearchManagerComponent } from './components/search-manager/search-manager.component';
import { EmptyChatComponent } from './components/empty-chat/empty-chat.component';
import { ChatStarterComponent } from './components/chat-starter/chat-starter.component';
import { ChannelIntergrationComponent } from './pages/channel-intergration/channel-intergration.component';
import { WhatsappFormComponent } from './components/intergrations/whatsapp-form/whatsapp-form.component';
import { MessangerFormComponent } from './components/intergrations/messanger-form/messanger-form.component';
import { TelegramFormComponent } from './components/intergrations/telegram-form/telegram-form.component';
import { LineFormComponent } from './components/intergrations/line-form/line-form.component';
import { WhatsappCardComponent } from './components/intergrations/cards/whatsapp-card/whatsapp-card.component';
import { MessengerCardComponent } from './components/intergrations/cards/messenger-card/messenger-card.component';
import { LineCardComponent } from './components/intergrations/cards/line-card/line-card.component';
import { TelegramCardComponent } from './components/intergrations/cards/telegram-card/telegram-card.component';
import { ApiCardComponent } from './components/intergrations/cards/api-card/api-card.component';
import { ClipboardModule } from 'ngx-clipboard';
import { TemplateManagerComponent } from './pages/template-manager/template-manager.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { DocumentViewerComponent } from './chat/document-viewer/document-viewer.component';
import { StriphtmlPipe } from './pipes/striphtml.pipe';
import { ArchiveManagerComponent } from './components/archive-manager/archive-manager.component';
import { ReadMoreComponent } from './components/read-more/read-more.component';



export function tokenGetter() {
  return localStorage.getItem("access_token");
}

@NgModule({
  declarations: [
    AppComponent,
    ChatViewComponent,
    SideBarMenuComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    MediaPreviewComponent,
    AudioPlayerComponent,
    VideoPlayerComponent,
    CircleProgressComponent,
    ChatItemComponent,
    PdfViewerComponent,
    DownloaderComponent,
    ByteformatterPipe,
    DurationPipe,
    MessagedatePipe,
    SignupComponent,
    LoginComponent,
    ContactComponent,
    ReversePipe,
    ProfileEditComponent,
    TemplateEditorComponent,
    HighlightPipe,
    ReplacePipe,
    ContactManagerComponent,
    SearchManagerComponent,
    ChatBoxComponent,
    EmptyChatComponent,
    ChatStarterComponent,
    ChatStarterComponent,
    ChannelIntergrationComponent,
    WhatsappFormComponent,
    MessangerFormComponent,
    TelegramFormComponent,
    LineFormComponent,
    WhatsappCardComponent,
    MessengerCardComponent,
    LineCardComponent,
    TelegramCardComponent,
    ApiCardComponent,
    TemplateManagerComponent,
    TemplateFormComponent,
    DocumentViewerComponent,
    StriphtmlPipe,
    ArchiveManagerComponent,
    ReadMoreComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    SocketIoModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    LightboxModule,
    BrowserAnimationsModule,
    ProgressbarModule.forRoot(),
    ModalModule.forRoot(),
    PlyrModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    TabsModule.forRoot(),
    InfiniteScrollModule,
    PickerModule,
    EmojiModule,
    ImgFallbackModule,
    ImageCropperModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      },
    }),
    NgxSkeletonLoaderModule,
    ClipboardModule,
    NgxSpinnerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }

  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
