/**
 * Enum definition for Chat channels
 */

export enum CHANNELS {
    WHATSAPP = "whatsapp",
    MESSANGER = "messanger",
    TELEGRAM = "telegram",
    LINE = "line",
}

