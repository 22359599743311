import { Component, NgZone, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'WhatsappChatApp';
  hideMasterTemplate = false;

  constructor(private zone: NgZone, private router: Router) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/signup' || event.url === '/login') {
          this.hideMasterTemplate = true;
        } else {
          this.hideMasterTemplate = false;
        }
      }
    });
  }

}
