import { TimeHMS } from './../entity/models/timehms';

import * as moment from 'moment';

export class TimeHelper {

  public static secondsToHMS(seconds: number): TimeHMS {
    const duration = moment.duration(seconds, 'seconds');
    const h = duration.hours();
    const m = duration.minutes();
    const s = duration.seconds();

    return new TimeHMS(h, m, s);
  }

}
