import { ToastrService } from 'ngx-toastr';
import { ApiResponse } from './../entity/models/api-response';
import { AuthService } from './../services/auth.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginUpForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.loginUpForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  public login(): void {
    this.submitted = true;
    if (this.loginUpForm.invalid) {
      return;
    }

    const formData = this.loginUpForm.value;

    console.log('form data ', formData);
    this.authService.login(formData).subscribe((res: any) => {
        this.toastr.success('Login successfull', 'Registration');
        this.gotoChat();
    },err=>{
      this.toastr.error('Login Failed.', 'Registration');
    });
  }


  get formControls(): any {
    return this.loginUpForm.controls;
  }

  gotoChat() {
    this.router.navigate(['/chat/start'], { queryParams: {} });
  }

}
