import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { saveAs as importedSaveAs } from "file-saver";
import { HttpHeaders } from '@angular/common/http';
import { HttpParamsTags } from 'src/app/entity/models/HttpParamsTags';

@Component({
  selector: 'app-downloader',
  templateUrl: './downloader.component.html',
  styleUrls: ['./downloader.component.scss']
})
export class DownloaderComponent implements OnInit {
  @Input() name: string = "Title";
  @Input() source: string;

  isDownloading: boolean = false;

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  ngOnInit(): void {

  }

  download() {
    if (this.isDownloading) {
      this.toastr.error('File downloading is already in progress ...', 'File Download');
      return;
    }
    this.isDownloading = true;
    this.downloadFile(this.source).subscribe(blob => {
      importedSaveAs(blob, this.name);
      this.toastr.success('File download completed ...', 'File Download');
      this.isDownloading = false;
    }, err => {
      this.toastr.error('Download failed', 'File Download');
      this.isDownloading = false;
    }, () => {
      this.isDownloading = false;
    });
  }

  s3Download() {

  }


  downloadFile(name): Observable<Blob> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(name, {params: new HttpParamsTags(true), responseType: 'blob', withCredentials: false });
  }

}
