import { TelegramFormComponent } from './../../telegram-form/telegram-form.component';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CHANNELS } from 'src/app/entity/enums/channels';
import { INTEGRATION_STATUS } from 'src/app/entity/enums/intergration-status';
import { IntegrationService } from 'src/app/services/integration.service';

@Component({
  selector: 'app-telegram-card',
  templateUrl: './telegram-card.component.html',
  styleUrls: ['./telegram-card.component.scss']
})
export class TelegramCardComponent implements OnInit {
  INTEGRATION_STATUS: typeof INTEGRATION_STATUS = INTEGRATION_STATUS;
  welcomeMessageModelReference: BsModalRef;
  status: INTEGRATION_STATUS;

  constructor(private integrationService:IntegrationService,private modalService: BsModalService) { }

  ngOnInit(): void {
    this.getStatus();

  }

  getStatus() {
    this.integrationService.getIntegation(CHANNELS.TELEGRAM).subscribe((res: any) => {
      console.log('channel ------------ ', res);
      this.status = res.status;
    });
  }

  openNewWelcomePopup() {
    this.welcomeMessageModelReference = this.modalService.show(TelegramFormComponent, {
      class: 'test',
      // initialState: {
      //   contact: contact
      // }
    });
    // this.welcomeMessageModelReference.content.closeBtnName = 'Close';
    // this.welcomeMessageModelReference.content.sendWelcomeRequest.subscribe((value) => {
    //   console.log("contact updated ", value);
    //   // this.messageTemplate = value;
    //   // this.sendMessage();
    //   this.welcomeMessageModelReference.hide();
    // });
  }

}
