import { BaseMessage } from './../entity/models/base-message';
import { ChatAvailibilityData } from './../entity/interfaces/chat-availability-data';
import { PaginationResultDto } from './../entity/models/dto/pagination-contact';
import { Attachment } from './../entity/models/attachment';
import { map } from 'rxjs/operators';
import { endpoints } from './../entity/consts/api-endpoints';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Message } from '../entity/models/message';
import { Observable } from 'rxjs';
import stringInject from 'stringinject';
import { MessageMetadata } from '@angular/localize/src/utils';
import { MessageContentMedia } from '../entity/models/message-content/message-content-media';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  audio: HTMLAudioElement;
  constructor(private http: HttpClient) {

  }

  playMusic() {
    if (this.audio) {
      this.audio.play();
    } else {
      this.audio = new Audio();
      this.audio.src = "../../assets/music/notification.mp3";
      this.audio.load();
      this.audio.play();
    }
  }


  getMessagesForCoversation(conversationId: number, page: number): Observable<PaginationResultDto<BaseMessage>> {
    const postEndPoint = stringInject(endpoints.getMessagesForConversation, { id: conversationId, pageNumber: page });
    const apiUrl = environment.BASE_URL + '/' + postEndPoint;
    return this.http.get(apiUrl).pipe(map((messagePage: PaginationResultDto<BaseMessage>) => {
      return messagePage;
    }));
  }


}
