import { Template } from './../entity/models/template';
import { endpoints } from './../entity/consts/api-endpoints';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import stringInject from 'stringinject';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private http: HttpClient) {

  }

  saveTemplate(template: Template) {
    return this.http.post(`${environment.NEW_API_URL}/${endpoints.getTemplates}`, template);
  }



  getTemplates(): Observable<any> {
    const param = 'inbox';
    const postEndPoint = stringInject(endpoints.getTemplates, { param: param });
    const apiUrl = environment.BASE_URL + '/' + postEndPoint;
    return this.http.get(apiUrl);
  }

  getWelcomeTemplate() {
    return this.http.get(`${environment.NEW_API_URL}/${endpoints.getWelcomeTemplate}`);
  }


}

