<div class="content-wrapper">
  <main>
    <div class="layout">
      <div class="container mt-5 mb-3">
        <div class="row">
          <div class="col-md-4">
            <app-whatsapp-card></app-whatsapp-card>
          </div>
          <div class="col-md-4">
            <app-messenger-card></app-messenger-card>
          </div>
          <div class="col-md-4">
            <app-line-card></app-line-card>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <app-telegram-card></app-telegram-card>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-4">
            <app-api-card></app-api-card>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
