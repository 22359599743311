<div class="content-wrapper content-wrapper-fluid">
  <main>
    <div class="layout">
      <!-- Start of Sign Up -->
      <div class="main order-md-2">
        <div class="start">
          <div class="container">
            <div class="col-md-12">
              <div class="content">
                <h1>Create Account</h1>
                <div class="third-party">
                  <button class="btn item bg-blue">
                    <i class="fab fa-facebook-f"></i>
                  </button>
                  <button class="btn item bg-teal">
                    <i class="fab fa-google"></i> </button>
                  <button class="btn item bg-purple">
                    <i class="fab fa-apple"></i>
                  </button>
                </div>
                <p>or use your email for registration:</p>
                <form class="signup" name="registration" [formGroup]="signUpForm" (ngSubmit)="signup()">
                  <div class="form-parent">
                    <div class="form-group">
                      <input type="text" id="inputName" class="form-control" placeholder="Company Name"
                        formControlName="name" [ngClass]="{ 'is-invalid': submitted && formControls.name.errors }">
                      <button class="btn icon"><i class="material-icons">person_outline</i></button>
                    </div>
                    <div class="form-group">
                      <input type="email" id="inputEmail" class="form-control" formControlName="email"
                        placeholder="Email Address"
                        [ngClass]="{ 'is-invalid': submitted && formControls.email.errors }">
                      <button class="btn icon"><i class="material-icons">mail_outline</i></button>
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="password" id="inputPassword" class="form-control" placeholder="Password"
                      formControlName="password"
                      [ngClass]="{ 'is-invalid': submitted && formControls.password.errors }">
                    <button class="btn icon"><i class="material-icons">lock_outline</i></button>
                  </div>
                  <button type="submit" class="btn button" formaction="index-2.html">Sign Up</button>
                  <div class="callout">
                    <span>Already a member? <a href="/login">Sign In</a></span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End of Sign Up -->
      <!-- Start of Sidebar -->
      <div class="aside order-md-1">
        <div class="container">
          <div class="col-md-12">
            <div class="preference">
              <h2>Welcome Back!</h2>
              <p>To keep connected with your friends please login with your personal info.</p>
              <a href="/login" class="btn button">Sign In</a>
            </div>
          </div>
        </div>
      </div>
      <!-- End of Sidebar -->
    </div> <!-- Layout -->
  </main>
</div>
