import { ApiResponse } from './../entity/models/api-response';
import { AuthService } from './../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signUpForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
    private authService: AuthService,
    private toastr: ToastrService,
    private route: Router,
    private aRoute: ActivatedRoute) {
    this.initForm();
  }

  ngOnInit(): void {
  }

  private initForm(): void {
    this.signUpForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  public signup(): void {
    this.submitted = true;
    if (this.signUpForm.invalid) {
      return;
    }
    const formData = this.signUpForm.value;
    console.log('form data ', formData);
    this.authService.signup(formData).subscribe((res: ApiResponse) => {
      console.log('res ', res);
      if (res.status) {
        this.toastr.success('Registration successfull.you may login now', 'Registration');
        this.navigateLogin();
      } else {
        this.toastr.error('Registration Failed.', 'Registration');
      }
    });
  }

  navigateLogin() {
    this.route.navigate(['login']);
  }

  get formControls(): any {
    return this.signUpForm.controls;
  }

}
