import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CHANNELS } from 'src/app/entity/enums/channels';
import { INTEGRATION_STATUS } from 'src/app/entity/enums/intergration-status';
import { IntegrationService } from 'src/app/services/integration.service';
import { WhatsappFormComponent } from '../../whatsapp-form/whatsapp-form.component';

@Component({
  selector: 'app-whatsapp-card',
  templateUrl: './whatsapp-card.component.html',
  styleUrls: ['./whatsapp-card.component.scss']
})
export class WhatsappCardComponent implements OnInit {

  INTEGRATION_STATUS: typeof INTEGRATION_STATUS = INTEGRATION_STATUS;
  welcomeMessageModelReference: BsModalRef;
  status: INTEGRATION_STATUS;
  constructor(private modalService: BsModalService,
    private integrationService: IntegrationService) {

  }
  ngOnInit(): void {
    this.getStatus();

  }

  getStatus() {
    this.integrationService.getIntegation(CHANNELS.WHATSAPP).subscribe((res: any) => {
      console.log('channel ------------ ', res);
      this.status = res.status;
    });
  }

  openNewWelcomePopup() {
    this.welcomeMessageModelReference = this.modalService.show(WhatsappFormComponent, {
      class: 'test',
      // initialState: {
      //   contact: contact
      // }
    });
    // this.welcomeMessageModelReference.content.closeBtnName = 'Close';
    // this.welcomeMessageModelReference.content.sendWelcomeRequest.subscribe((value) => {
    //   console.log("contact updated ", value);
    //   // this.messageTemplate = value;
    //   // this.sendMessage();
    //   this.welcomeMessageModelReference.hide();
    // });
  }
}
