/**
 * Format duration in seconds to hours, minutes and seconds ex: 00:03:26
 */


import { TimeHelper } from './../helpers/time-helper';
import { TimeHMS } from './../entity/models/timehms';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(duration: number): string {
    if (!duration) {
      return '';
    }
    const formattedDuration = TimeHelper.secondsToHMS(duration);
    return `${formattedDuration.hours}:${formattedDuration.minutes}:${formattedDuration.seconds}`;
  }

}

