import { MessageStatus } from './../entity/enums/message-status';
import { Conversation } from './../entity/models/conversation';
import { MessageRepo } from './../entity/models/message-repo';
import { Injectable } from '@angular/core';
import { Message } from '../entity/models/message';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Client } from '../entity/models/user';

@Injectable({
  providedIn: 'root'
})
export class MessageRepoService {


  messageRepos: Array<MessageRepo> = [];

  private repochanged = new Subject<MessageRepo>();
  repoChanged$: Observable<MessageRepo>;

  constructor() {
    this.repoChanged$ = this.repochanged.asObservable();
  }

  emitRepoChange() {
    this.repochanged.next();
  }

  getRepoListner() {
    return this.repochanged;
  }

  public getRepoByConversationId(conversationId: number) {
    /**
     * get message repocitory by  conversation id
     */
    const matchedRepo = this.messageRepos.find(repo => {
      console.log(' x ', repo.conversationId, conversationId);
      return repo.conversationId === conversationId;
    });

    return matchedRepo;
  }


  // create a new repo
  public createRepo(contact: Client, messages: Message[]) {

    // create new message repocitory
    const newRepo = new MessageRepo();
    newRepo.active = false;
    // newRepo.messages = messages;
    newRepo.user = contact;
    newRepo.conversationId = contact.conversationId;
    newRepo.currentMessagePage++;
    this.messageRepos.push(newRepo);
    return newRepo;

  }


  public findRepoByConversationId(conversationId) {
    const matchedRepo = this.messageRepos.find(repo => {
      console.log(repo.user.conversationId, conversationId);
      return repo.user.conversationId === conversationId;
    });
    return matchedRepo;
  }

  // update existing repo

  // clean repo

  // trim repo

}
