export enum MessageStatus {
  ACCEPTED = "ACCEPTED",
  QUEUED = "QUEUED",
  SENDING = "SENDING",
  PENDING = "PENDING",
  SENT = "SENT",
  FAILED = "FAILED",
  DELIVERED = "DELIVERED",
  UNDELIVERED = "UNDELIVERED",
  RECEIVING = "RECEIVING",
  RECEIVED = "RECEIVED",
  READ = "READ",
  UNKNOWN = "UNKNOWN"
}
