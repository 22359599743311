import { TemplateService } from './../../services/template.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CHANNELS } from 'src/app/entity/enums/channels';
import { Template } from 'src/app/entity/models/template';
import { templateContentValiadater } from '../../validators/template-validator';

@Component({
  selector: 'app-template-form',
  templateUrl: './template-form.component.html',
  styleUrls: ['./template-form.component.scss']
})
export class TemplateFormComponent implements OnInit {
  integrationForm: FormGroup;
  submitted: boolean = false;
  @Input() isEdit: boolean = false;
  @Output() templateSave: EventEmitter<Template> = new EventEmitter();

  constructor(public bsModalRef: BsModalRef,
    private templateService: TemplateService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.initForm();
    if (this.isEdit) {
      this.fillForm();
    }
  }

  fillForm() {
    // this.integrationService.getIntegation(CHANNELS.WHATSAPP).subscribe(res => {
    //   const integation = res;
    //   this.integrationForm.setValue({
    //     firstName: this.profile.firstName,
    //     lastName: this.profile.lastName,
    //     phone: this.profile.phone,
    //     userId: this.profileId,
    //     imgSrc: null
    //   });
    // }, err => {
    //   console.log('no integation found');
    // });
  }

  private initForm(): void {
    this.integrationForm = this.formBuilder.group({
      content: [null, [Validators.required, templateContentValiadater]],
      name: [null, [Validators.required]],
      isWelcome: [null, []]
    });

    this.integrationForm.controls['isWelcome'].valueChanges.subscribe({
      next: (value) => {
        this.integrationForm.controls['content'].updateValueAndValidity();
      }
    });
  }

  get formControls(): any {
    return this.integrationForm.controls;
  }

  submit() {
    this.submitted = true;
    console.log(this.integrationForm.value, this.integrationForm.invalid, this.integrationForm);
    if (!this.integrationForm.invalid) {
      this.templateService.saveTemplate(this.integrationForm.value).subscribe(res => {
        console.log('res ', res);
        this.templateSave.emit(res as Template);
      }, err => {
        console.log('error ', err);
      });
    }
  }



}
