import { TEMPLATE_NAMES } from './../entity/enums/template-names';
export class TemplateHelper {

  public static getTemplateName(name: string) {
    
    if (name === "4_or_5_star_reply") {
        name = "four_or_five_star_reply";
    } else if (name === "1_or_3_star_reply") {
        name = "one_or_three_star_reply";
    } else if (name === "24_hour_limit_req_for_cust_reply_4") {
        name = "twentyfour_hour_limit_req_for_cust_reply_4";
    }

    let templateName = name;
    if (TEMPLATE_NAMES[name]) {
        templateName = TEMPLATE_NAMES[name];
    }

    return templateName;

  }

}
