import { endpoints } from './../entity/consts/api-endpoints';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import stringInject from 'stringinject';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(private http: HttpClient) { }

  searchContacts(phone: string) {
    const postEndPoint = stringInject(endpoints.searchContact, { phone });
    return this.http.get(environment.BASE_URL + '/' + postEndPoint);
  }

  getContacts(page: number) {
    return this.http.get(`${environment.NEW_API_URL}/${endpoints.getContacts2}?page=${page}&limit=5`);
  }

  getArchivedContacts(page: number) {
    return this.http.get(`${environment.NEW_API_URL}/${endpoints.getArchivedContacts}?page=${page}&limit=5`);
  }

}
