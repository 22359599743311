export const endpoints = {

  getAllMessages: 'message',
  getContacts: 'conversation',//remove
  getContacts2: 'contact',
  getMessagesForConversation: 'message/message-page-list/{id}?page={pageNumber}&limit=10',
  searchContact: 'contact/search/{phone}',
  searchMessages: 'message/message-search/{searchText}',
  getTemplates: 'template/get-templates/{param}',
  getWelcomeTemplate:'template/welcome',
  checkConversationAvailibility: 'conversation/chat-time-report/{conversationId}',
  getClient: 'client/{clientId}',
  getClientByChatNumber: 'client/findbychatnumber/{chatNumber}',
  getProfile: 'profile/{profileId}',
  updateProfile: 'profile',
  archiveChat: 'conversation/archive-chat',
  getArchivedContacts: 'contact/archived',

  intergrateRequestWhatsapp: 'intergration/request-whatsapp',
  intergrateRequestTelegram: 'intergration/request-telegram',
  integrationStatus: 'intergration/integration-status/{channelName}',
  integration: 'intergration/integration/{channelName}',
  apiKey: 'intergration/api-key'


}

