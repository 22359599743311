<div class="requests">
  <div class="title">
    <h1>Start new chat</h1>
    <button id="closecontact" type="button" class="btn"  (click)="bsModalRef.hide()" data-dismiss="modal" aria-label="Close"><i
        class="material-icons">close</i></button>
  </div>
  <div class="content">
    <form [formGroup]="newChatForm" (ngSubmit)="sendWelcome()">
      <div class="form-group">
        <label class='res-label' for="participant">Recipient:</label>

        <div class="user" id="recipient">

          <ngx-intl-tel-input
            [cssClass]="{ 'is-invalid': newChatSubmitted && formControls.phone.errors,'form-control phone':true }"
            [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true" [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
            [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.SriLanka" [maxLength]="15"
            [tooltipField]="TooltipLabel.Name" [phoneValidation]="true" [separateDialCode]="separateDialCode"
            name="phone" formControlName="phone">
          </ngx-intl-tel-input>

          <!-- <input type="text" name="phone" id="phone" formcontrolname="phone" aria-describedby="phoneHelp"
            placeholder="Enter Phone" class="form-control phone" formControlName="phone"
            [ngClass]="{ 'is-invalid': newChatSubmitted && formControls.phone.errors }" /> -->
        </div>
      </div>
      <!-- <div class="form-group">
        <label for="topic">Topic:</label>
        <input type="text" class="form-control" id="topic" placeholder="What's the topic?" required>
      </div> -->
      <input type="text" type="hidden" class="form-control" formControlName="templateId">

      <div class="form-group">
        <label for="message">Message:</label>
        <textarea [ngClass]="{ 'is-invalid': newChatSubmitted && formControls.templateText.errors }"  class="text-control" id="message" formControlName="templateText"
          placeholder="Send your welcome message..."></textarea>
      </div>
      <button type="submit" class="btn button w-100">Start New Chat</button>
    </form>
  </div>
</div>
