import { DOCUMENT_TYPES } from './../entity/enums/document-types';
export class FileTypeHelper {

  public static getDocumentType(type: string) {
    // Sync with backend
    // Return file type based on mime type
    // This is only for frontend please validate file types from the backend as well.

    let documentType = DOCUMENT_TYPES.DOCUMENT;
    if (type.match('image.*')) {
      documentType = DOCUMENT_TYPES.IMAGE;
    } else if (type.match('audio.*')) {
      documentType = DOCUMENT_TYPES.AUDIO;
    } else if (type.match('video.*')) {
      documentType = DOCUMENT_TYPES.VIDEO;
    } else if (type == 'application/pdf') {
      documentType = DOCUMENT_TYPES.PDF;
    }

    return documentType;

  }


  public static getNamewithoutExtension(file: string): string {
    // split extension and omit ex: x.mp3 -->x
    return file.replace(/\.[^/.]+$/, '');
  }


}
