<div class="message" *ngIf="message.direction == MessageDirection.RECEIVED && loaded">
  <img class="avatar-md" [src]="client?.avatar" data-toggle="tooltip" data-placement="top" [title]="client?.avatar"
    alt="avatar">
  <div class="text-main">
    <div class="text-group">
        <!-- Text only messages -->
        <div *ngIf="message.type==='TEXT'" class="text-wrap">
          <app-read-more [text]="message.content" [textLength]="700" [linkColor]="''" [textColor]="''"></app-read-more>
        </div>

        <!-- Image messages -->
        <div *ngIf="message.type==='MEDIA' && message.content.documentType===DOCUMENT_TYPES_BIND.IMAGE"
          (click)="openImageBox(message.content.cloudURL)">
          <div class="media-wrap">
            <div class="img-view">
              <img [src]="message.content.cloudURL" alt="">
            </div>
          </div>
        </div>

        <!-- Audio messages -->
        <div *ngIf="message.type==='MEDIA' && message.content.documentType===DOCUMENT_TYPES_BIND.AUDIO">
          <div class="media-wrap audio-message">
            <app-audio-player [duration]="message.content?.duration" [size]="message.content?.filesize"
              [audioSource]="message.content.cloudURL" [name]="mediaName">
            </app-audio-player>
          </div>
        </div>

        <!-- Video messages -->
        <div *ngIf="message.type==='MEDIA' && message.content.documentType===DOCUMENT_TYPES_BIND.VIDEO">
          <div class="media-wrap audio-msg">
            <app-video-player [duration]="message.content?.duration" [size]="message.content?.size" [name]="mediaName"
              [videoSource]="message.content?.cloudURL" [thumbnail]="message.content?.thumbnail"></app-video-player>
          </div>
        </div>

        <!-- PDF  messages -->
        <div *ngIf="message.type==='MEDIA' && message.content.documentType===DOCUMENT_TYPES_BIND.PDF">
          <div class="media-wrap pdf-msg">
            <app-pdf-viewer [source]="message.content.cloudURL" [name]="message.content.cloudURL"></app-pdf-viewer>
          </div>
        </div>

        <!-- Other documents -->
        <div *ngIf="message.type==='MEDIA' && message.content.documentType===DOCUMENT_TYPES_BIND.DOCUMENT">
          <div class="media-wrap doc-msg">
            <app-document-viewer [source]="message.content.cloudURL" [name]="message.content.cloudURL">
            </app-document-viewer>
          </div>
        </div>
    </div>
    <!-- <p> {{message.time | messageDate}}</p> -->
    <span>{{message.time | messageDate}}</span>
  </div>
</div>
<div class="message me" *ngIf="message.direction == MessageDirection.SENT && loaded" [ngClass]="{
  'msg-text': message.type==MEDIA_TYPES_BIND.TEXT || message.type==MEDIA_TYPES_BIND.TEMPLATE }">
  <div class=" text-main">
    <div class="text-group me">
      <div class="text me">
        <!-- Text only messages -->
        <div *ngIf="message.type==='TEXT'">
          <app-read-more [text]="message.content" [textLength]="700" [linkColor]="'cyan'" [textColor]="'#fff'"></app-read-more>
        </div>

        <!-- Template messages -->
        <div *ngIf="message.type==='TEMPLATE'">
          <app-read-more [text]="message.content.renderedText" [textLength]="700" [linkColor]="'cyan'" [textColor]="'#fff'"></app-read-more>
        </div>

        <!-- Image messages -->
        <div *ngIf="message.type==='MEDIA' && message.content.documentType===DOCUMENT_TYPES_BIND.IMAGE"
          (click)="openImageBox(message.content.cloudURL)">
          <div class="media-wrap">
            <p>{{message.content}}</p>
            <div class="img-view">
              <img [src]="message.content.cloudURL" alt="">
            </div>
          </div>
        </div>

        <!-- Audio messages -->
        <div *ngIf="message.type==='MEDIA' && message.content.documentType===DOCUMENT_TYPES_BIND.AUDIO">
          <div class="media-wrap audio-message">

            <app-audio-player [duration]="message.content?.duration" [size]="message.content?.size"
              [audioSource]="message.content.cloudURL" [name]="mediaName">
            </app-audio-player>
          </div>
        </div>

        <!-- Video messages -->
        <div *ngIf="message.type==='MEDIA' && message.content.documentType===DOCUMENT_TYPES_BIND.VIDEO">
          <div class="media-wrap audio-msg">
            <p>{{message.content}}</p>
            <app-video-player [duration]="message.content?.duration" [size]="message.content?.size" [name]="mediaName"
              [thumbnail]="message.content?.thumbnail" [videoSource]="message.content?.cloudURL">
            </app-video-player>
          </div>
        </div>

        <!-- PDF  messages -->
        <div *ngIf="message.type==='MEDIA' && message.content.documentType===DOCUMENT_TYPES_BIND.PDF">
          <div class="media-wrap pdf-msg">
            <p>{{message.content}}</p>
            <app-pdf-viewer [source]="message.content.cloudURL" [name]="mediaUrl"></app-pdf-viewer>
          </div>
        </div>

        <!-- Other documents -->
        <div *ngIf="message.type==='MEDIA' && message.content.documentType===DOCUMENT_TYPES_BIND.DOCUMENT">
          <div class="media-wrap doc-msg">
            <app-document-viewer [source]="message.content.cloudURL" [name]="message.content.cloudURL">
            </app-document-viewer>
          </div>
        </div>

      </div>
    </div>
    <div class="time-view">
      <p> {{message.time | messageDate}}</p>
    </div>

    <span>
      <i class="fas fa-xs" [ngClass]="{
                              'fa-clock': message.status=='PENDING',
                                'fa-check msg-status-icon-color': message.status=='SENT',
                              'fa-check-double  msg-status-icon-color': message.status=='DELIVERED',
                              'fa-check-circle msg-status-read-icon-color': message.status=='READ',
                              'fa fa-exclamation-circle msg-status-fail-icon-color':message.status=='FAILED'
                            }"></i>
    </span>
  </div>
</div>
