import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild, ElementRef, TemplateRef, ChangeDetectorRef, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PlyrComponent } from 'ngx-plyr';
import { FileTypeHelper } from 'src/app/helpers/file-helper';
import { TimeHMS } from 'src/app/entity/models/timehms';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
  @ViewChild('vplayer', { static: false, read: ElementRef }) target: ElementRef;
  @Input() videoSource: string;
  @Input() thumbnail: string;
  @Input() name: string;
  @Input() size: number;
  @Input() duration: TimeHMS;

  @ViewChild(PlyrComponent) plyr: PlyrComponent;
  player: Plyr;
  constructor(private modalService: BsModalService, private changeDetector: ChangeDetectorRef) { }
  modalRef: BsModalRef;

  ngOnInit(): void {
  }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg video-view-model' });
  }


  initPlaye(event: any): void {
    this.player = event;
    this.player.play();
  }


}
