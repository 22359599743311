/**
 * Enum definition for chat types 
 */

export enum MESSAGE_TYPES {
    TEXT="TEXT",
    MEDIA="MEDIA",
    TEMPLATE = "TEMPLATE"

  }


