import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-line-card',
  templateUrl: './line-card.component.html',
  styleUrls: ['./line-card.component.scss']
})
export class LineCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openNewSetupPopup(){
    alert('Not implemented');
  }

}
