import { DOCUMENT_TYPES } from '../enums/document-types';

export class MediaResource {
  loadComplete: boolean;
  localUrl: string;
  localFile: File;
  cloudUrl: string;
  status: UPLOADSTATUS;
  name: any;
  docType: DOCUMENT_TYPES;
  thubnail?: string;
  thumbnailCloud?:string;
  attachmentId: number;
  duration: number;
  size?: any;
}

export enum UPLOADSTATUS {
  PENDING,
  SUCCESS,
  FAIL
};

