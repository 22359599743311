<a href="#" class="all online contact">
  <img class="avatar-md" src="./../assets/img/avatars/avatar-female-1.jpg" data-toggle="tooltip" data-placement="top"
    title="Janette" alt="avatar">
  <div class="status">
    <i class="material-icons online">fiber_manual_record</i>
  </div>
  <div class="data">
    <h5>Janette Dalton</h5>
    <p>Sofia, Bulgaria</p>
  </div>
  <div class="person-add">
    <i class="material-icons">person</i>
  </div>
</a>
