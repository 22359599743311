import { BaseMessage } from './../entity/models/base-message';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Client } from '../entity/models/user';
import { MessageService } from './message.service';
import { CHANNELS } from '../entity/enums/channels';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatBoxService {

  public messagesList: Array<BaseMessage> = [];
  private page: number = 1;
  private client: Client;
  windowOpen: boolean = true;
  private messageReciveSubject = new Subject<BaseMessage>();

  constructor(private messageService: MessageService) {
  }

  getMessageRecievedListner(): Observable<BaseMessage> {
    return this.messageReciveSubject.asObservable();
}

  getActiveClient() {
    return this.client;
  }

  messageReceived(message: BaseMessage) {
    this.addRecievedMessage(message);
    this.setWindowStatus(true);
  }

  messageStatusReceived(message: BaseMessage) {
    const { messageIndex, message: messageFound } = this.getMessageFromRepo(message.messageId) || {};
    if (messageFound) {
      console.log(messageFound);
      console.log(this.messagesList[messageIndex].status);
      this.messagesList[messageIndex].status = message.status;
      console.log(this.messagesList[messageIndex].status);
      console.log(message.status);
      console.log(this.messagesList);
    }
  }

  getMessageFromRepo(messageId: string): { messageIndex: number, message: BaseMessage } {
    const messageIndex = this.messagesList.findIndex(item => {
      console.log("checking ", item.messageId,
        messageId);
      return item.messageId === messageId;
    });
    if (messageIndex > -1) {
      return { messageIndex: messageIndex, message: this.messagesList[messageIndex] };
    }
  }

  initRepo(client: Client) {
    this.page = 1;
    this.clearChatRepo();
    this.client = client;
    this.setWindowStatus(this.client.windowOpen);
  }

  clearChatRepo() {
    this.messagesList = [];
    this.resetWindowStatus();
  }

  featchPage() {
    return this.messageService.getMessagesForCoversation(this.client.conversationId, this.page).pipe(map(res => {
      console.log('res ', res);
      this.page++;
      this.addMessages(res.data);
    }));
  }

  addRecievedMessage(message: BaseMessage) {
    this.messagesList.push(message);
    this.messageReciveSubject.next(message);
  }

  addSentMessage(message: BaseMessage) {
    console.log('before ---- ', this.messagesList);
    this.messagesList.push(message);
    console.log('after ---- ', this.messagesList);
  }

  addMessages(messages: Array<BaseMessage>) {
    const orderedMessages = messages.reverse();
    this.messagesList.unshift(...orderedMessages);
  }

  setWindowStatus(status: boolean) {
    if (this.client.channelName == CHANNELS.WHATSAPP) {
      this.windowOpen = status;
    }
    console.log(this.windowOpen);
  }

  resetWindowStatus() {
    this.windowOpen = true;
  }



}
