import { MessageDirection } from '../enums/message-direction';
import { CHANNELS } from './../enums/channels';
import { MessageStatus } from './../enums/message-status';
import { MESSAGE_TYPES } from './../enums/message-types';
import { MessageContentMedia } from './message-content/message-content-media';
import { MessageContentTemplate } from './message-content/message-content-template';


export class BaseMessage {
  type: MESSAGE_TYPES;
  messageId?: string;
  status?: MessageStatus;
  content: string | MessageContentTemplate | MessageContentMedia;
  to: number;
  from: string;
  chatNumber?: string;
  companyId: number;
  channel: CHANNELS;
  conversationId: number;
  //meta data
  isWelcome: boolean;
  direction: MessageDirection;
  time?: Date;
  clientId?:number;
  avatar?:string;
  archived: boolean;
}
