<div class="container">
  <div class="row">
    <div class="col-md-12 mx-auto">
      <div class="template-from form">

        <h4>Browse Templates</h4>

        <form [formGroup]="userForm" (ngSubmit)="send()">
          <div>
            <select class="form-control" formControlName="template" (ngModelChange)="changeTemplate($event)">
              <option value="">Please select</option>
                <optgroup *ngFor="let group of templatesGroups" label="{{(group != null) ? group : 'Other'}}">
                  <ng-container *ngFor="let template of templates">
                    <option *ngIf="template.group == group" [ngValue]="template">
                      {{ getTemplateName(template.name) }}
                    </option>
                  </ng-container>
                </optgroup>
            </select>
          </div>
          <div *ngFor="let phone of getPhonesFormControls(); let i = index" [hidden]="selectedTemplate.companyId == 4 && selectedTemplate.templateId>151 && templatePlaceholdersNoBrackets[i]>0">
            <label *ngIf="templatePlaceholdersNoBrackets[i]>0">Field {{templatePlaceholdersNoBrackets[i]}}</label>
            <label [hidden]="templatePlaceholdersNoBrackets[i]>0">{{templatePlaceholdersNoBrackets[i]}} </label>
            <input class="form-control" [formControl]="phone" type="text">
          </div>
          <button type="submit" class="mt-4 form-control btn btn-primary">Send</button>

        </form>

        <div *ngIf="templateRenderedText" class="mt-3 alert alert-primary" role="alert">
          {{templateRenderedText | striphtml}}
        </div>
      </div>
    </div>
  </div>
</div>
