import { MessageContentMedia } from './../entity/models/message-content/message-content-media';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient) {

  }

  public uploadImage(image: File): Observable<MessageContentMedia> {
    const formData = new FormData();
    formData.append('file', image);
    return this.http.post<MessageContentMedia>(environment.BASE_URL + '/media/save-v2', formData);
  }

}
