<div class="discussions">
    <h1>Archive</h1>
    <div class="spinner-wrap">
      <ngx-spinner [name]="contactSpinner" bdColor="transparent" size="medium" color="#2fe7ce" type="ball-clip-rotate"
        [fullScreen]="false">
        <p style="color: rgb(0, 0, 0)"> Loading... </p>
      </ngx-spinner>
    </div>
    <div class="list-group" id="chats" role="tablist">
  
  
      <!-- "conversationId": 1,
      "userId": 5,
      "picture": "",
      "firstName": "madhawa whatapp",
      "lastName": "user",
      "chatNumber": "+94717936771",
      "lastUpTime": null,
      "unreadCount": 0,
      "lastMessage": "hi",
      "channel": "whatsapp" -->
      
      <a *ngFor="let contact of contacts" (click)="selectContact(contact)"
        class="filterDiscussions contact-list all unread single" id="list-empty-list" data-toggle="list" role="tab">
        <img class="avatar-md profile-avatar" [src]="contact.avatar" data-toggle="tooltip" data-placement="top"
          title="Michael" alt="avatar">
        <div class="chat-channel-view" [ngClass]="['channel-'+contact?.channelName]">
        </div>
        <div *ngIf="contact.unreadCount" class="new bg-pink">
          <span>+{{contact.unreadCount}}</span>
        </div>
        <div class="data">
          <h5>{{contact.firstName ? (contact.firstName=='new' ? contact.chatNumber : contact.firstName) :
            contact.chatNumber}}</h5>
          <span>{{contact.lastUpTime | date :'EEEE' | slice:0:3}}</span>
  
          <span>
            <button class="btn" (click)="navigateToProfilePage(contact);$event.stopPropagation()">
              <i class="material-icons">create</i>
            </button>
          </span>
          <p>{{ (contact.lastMessage?.length>20)? (contact.lastMessage | slice:0:20 | striphtml)+'..':(contact.lastMessage | striphtml) }}</p>
        </div>
      </a>
  
      <button type="button" class="btn btn-primary" (click)="loadArchivedContacts()" *ngIf="contacts.length > 0">
        Load More
      </button>

      <span style="text-align: center; color: #b5afaf; font-size: large;" *ngIf="contacts.length == 0">No Clients Found</span>
  
    </div>
  </div>  