import { Client } from './../../entity/models/user';
import { MessageContentMedia } from './../../entity/models/message-content/message-content-media';
import { BaseMessage } from './../../entity/models/base-message';
import { Attachment } from './../../entity/models/attachment';
import { TimeHelper } from './../../helpers/time-helper';
import { MESSAGE_TYPES } from './../../entity/enums/message-types';
import { DOCUMENT_TYPES } from './../../entity/enums/document-types';
import { TimeHMS } from './../../entity/models/timehms';
import { MessageDirection } from './../../entity/enums/message-direction';
import { Lightbox } from 'ngx-lightbox';
import { Message } from './../../entity/models/message';
import { environment } from 'src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-item',
  templateUrl: './chat-item.component.html',
  styleUrls: ['./chat-item.component.scss']
})
export class ChatItemComponent implements OnInit {
  DOCUMENT_TYPES_BIND = DOCUMENT_TYPES; // html bindings
  MEDIA_TYPES_BIND = MESSAGE_TYPES; // html bindings

  MessageDirection = MessageDirection;
  @Input() message: BaseMessage;
  @Input() client: Client;

  duration?: TimeHMS;
  mediaType: DOCUMENT_TYPES;
  mediaUrl: string;
  mediaName: string;
  attachment?: Attachment;

  // load completed?
  loaded: boolean = true;

  constructor(private lightbox: Lightbox) {
  }

  ngOnInit(): void {
    this.initMedia();
  }

  initMedia(): void {

  }

  openImageBox(url: string): void {
    /**
     * Open image with lightbox
     */
    const album = [];
    const img = {
      src: url
    };
    album.push(img);
    this.lightbox.open(album, 0);
  }


}
