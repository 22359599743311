<div class="requests">
  <div class="title">
    <h1>Setup Telegram</h1>
    <button type="button" class="btn" (click)="bsModalRef.hide()" data-dismiss="modal" aria-label="Close">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="content">
    <form [formGroup]="integrationForm" (ngSubmit)="submit()">
      <div class="form-group">
        <label for="user">Channel a name</label>
        <input type="text" class="form-control" id="user" formControlName="channelName"
          [ngClass]="{ 'is-invalid': submitted && formControls.channelName.errors }">
      </div>
      <div class="form-group">
        <label for="user">Bot Username</label>
        <input type="text" class="form-control" formControlName="username"
          [ngClass]="{ 'is-invalid': submitted && formControls.username.errors }">
      </div>
      <div class="form-group">
        <label for="welcome">Bot Token</label>
        <input type="text" class="form-control" formControlName="token"
          [ngClass]="{ 'is-invalid': submitted && formControls.token.errors }">
      </div>
      <button type="submit" class="btn button w-100">Submit</button>
    </form>
  </div>
</div>
