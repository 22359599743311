import { Pipe, PipeTransform } from '@angular/core';
// war : performance improvement is required.
@Pipe({
  name: 'reverse',
  pure: false
})
export class ReversePipe implements PipeTransform {

  transform(value) {
    return value.slice().reverse();
  }

}
