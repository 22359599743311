import { map } from 'rxjs/operators';
import { endpoints } from './../entity/consts/api-endpoints';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import stringInject from 'stringinject';
import { Client } from '../entity/models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {

  }


  getContacts(): Observable<any> {
    return this.http.get(`${environment.NEW_API_URL}/${endpoints.getContacts}`);
  }

  getClient(clientId: number) {
    const postEndPoint = stringInject(endpoints.getClient, { clientId: clientId });
    const apiUrl = environment.BASE_URL + '/' + postEndPoint;
    return this.http.get<Client>(apiUrl);
  }

  getClientByChatNumber(chatNumber: string) {
    const postEndPoint = stringInject(endpoints.getClientByChatNumber, { chatNumber: chatNumber });
    const apiUrl = environment.BASE_URL + '/' + postEndPoint;
    return this.http.get<Client>(apiUrl);
  }
}

