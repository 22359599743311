<div class="doc-view">
  <div class="pdf-view">

    <div class="download-doc">

      <app-downloader [name]="name" [source]="source"></app-downloader>

    </div>

    <i class="material-icons">picture_as_pdf</i>
  </div>
</div>
