<div class="card p-3 mb-2">
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-row align-items-center">
      <div class="icon">
        <img src="./../../../assets/img/integrations/whatsapp.png" class="card-img" alt="...">
      </div>
      <div class="ms-2 c-details">
        <h6 class="mb-0 pl-2">Whatsapp</h6>
      </div>
    </div>
    <div class="badge"> <span>Free</span> </div>
  </div>
  <div class="mt-5">
    <p>Whatsapp</p>
    <p>Whatsapp chat and connect with clients</p>
    <button *ngIf="INTEGRATION_STATUS.NOTINSTALLED===status" (click)="openNewWelcomePopup()"
      class="btn btn-primary">Setup</button>
    <button *ngIf="INTEGRATION_STATUS.REQUESTED===status" class="btn btn-primary">Pending</button>
    <button *ngIf="INTEGRATION_STATUS.INSTALLED===status" class="btn btn-primary">
      Installed <i class="fas fa-pen"></i>
    </button>
  </div>
</div>
