<div class="container rounded bg-white mt-5 mb-5">
  <div class="profile-wrapper">
    <form action="#" name="profile" [formGroup]="profileForm" (ngSubmit)="updateProfile()">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h4 class="text-right">Contact Infomation</h4>
          </div>

          <div class="profile-view d-flex align-items-start py-3 border-bottom">
            <img [src]="imgFile" class="img" alt="">
            <div class="pl-sm-4 pl-2" id="img-section"> <b>Profile Photo</b>
              <p>Accepted file type .png. Less than 1MB</p>
              <input type="file" class="btn button border" id="file" formControlName="file"
                (change)="onImageChange($event)">
            </div>
          </div>

          <div class="form-group">
            <div class="row mt-2">

              <div class="col-md-6">
                <label for="exampleInputEmail1">First Name</label>
                <input type="text" name="fname" class="form-control" formControlName="firstName"
                  [ngClass]="{ 'is-invalid': submitted && formControls.firstName.errors }" aria-describedby="nameHelp"
                  placeholder="Enter email">
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleInputEmail1">Last Name</label>
                  <input type="text" name="lastName" formControlName="lastName" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && formControls.lastName.errors }" aria-describedby="nameHelp"
                    placeholder="Enter Password">
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Phone Number</label>
            <input readonly type="text" name="password" formControlName="phone" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && formControls.phone.errors }" aria-describedby="emailHelp"
              placeholder="Enter Password">
          </div>
          <div class="text-center mb-3">
            <button type="submit" class=" btn btn-block mybtn btn-primary tx-tfm">Update Profile</button>
          </div>


          <!-- <div>
        <div class="text-center col-md-8">
          <h5>Crop Image</h5>
          <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 4"
            [resizeToWidth]="256" format="png" (imageCropped)="imageCropped($event)"></image-cropper>
        </div>

        <div class="text-center col-md-4">
          <h5>Preview</h5>
          <img [src]="croppedImage" />
        </div>

      </div> -->
        </div>
      </div>
    </form>
  </div>

</div>
