import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseMessage } from './../../entity/models/base-message';
import { ProfileEditComponent } from './../../page/profile-edit/profile-edit.component';
import { PaginationResultDto } from 'src/app/entity/models/dto/pagination-contact';
import { ContactsService } from 'src/app/services/contacts.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from 'src/app/services/user.service';
import { Client } from 'src/app/entity/models/user';
import { ChatArchiveRequest } from './../../entity/models/chat-archive-request';

@Component({
  selector: 'app-archive-manager',
  templateUrl: './archive-manager.component.html',
  styleUrls: ['./archive-manager.component.scss']
})
export class ArchiveManagerComponent implements OnInit {
  contactSpinner = 'contactSpinner';
  currentConactPage = 1;
  contacts: Client[] = [];
  @Output() contactClicked: EventEmitter<number> = new EventEmitter();
  editContactModelReference: BsModalRef;
  @Input() contactDetails: Client;
  chatArchiveRequestVar = new ChatArchiveRequest();
  constructor(private spinner: NgxSpinnerService,
    private contactService: ContactsService,
    private modalService: BsModalService) { }

  ngOnInit(): void {
    this.loadArchivedContacts();
  }

  ngOnChanges(changes) {
    if (this.contactDetails) {
      this.currentConactPage = 1;
      this.contacts = [];
      this.loadArchivedContacts();
    }
    
  }

  public loadArchivedContacts() {
    this.spinner.show(this.contactSpinner);

    this.contactService.getArchivedContacts(this.currentConactPage).subscribe((conversationPage: PaginationResultDto<Client>) => {
      conversationPage.data.forEach((contact) => {
        const contactExist = false;
        if (!contactExist) {
          this.contacts.push(contact);
          console.log('contacts ===', this.contacts);
        }
      });
    }, err => { }, () => {
      this.spinner.hide(this.contactSpinner);
    });

    this.currentConactPage++;
  }

  selectContact(contact: Client) {
    contact.unreadCount = 0;
    this.contactClicked.emit(contact.userId);
  }

  navigateToProfilePage(contact: Client) {
    this.openEditContactPopup(contact);
    return false;
  }

  openEditContactPopup(contact: Client) {
    console.log("Browser");
    this.editContactModelReference = this.modalService.show(ProfileEditComponent, {
      class: 'modal-lg',
      initialState: {
        contact: contact
      }
    });
    this.editContactModelReference.content.closeBtnName = 'Close';
    this.editContactModelReference.content.profileUpdated.subscribe((value) => {
      console.log("contact updated ", value);
      // this.messageTemplate = value;
      // this.sendMessage();
      this.editContactModelReference.hide();
    });
  }

  receiveMessage(message: BaseMessage) {
    this.contacts = this.contacts.filter(contact => {
      return contact.chatNumber != message.chatNumber;
    });
  }

}
