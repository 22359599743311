import { IntegrationService } from './../../../services/integration.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CHANNELS } from 'src/app/entity/enums/channels';

@Component({
  selector: 'app-whatsapp-form',
  templateUrl: './whatsapp-form.component.html',
  styleUrls: ['./whatsapp-form.component.scss']
})
export class WhatsappFormComponent implements OnInit {
  integrationForm: FormGroup;
  submitted: boolean = false;
  @Input() isEdit: boolean = false;

  constructor(public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private integrationService: IntegrationService) { }

  ngOnInit(): void {
    this.initForm();
    if (this.isEdit) {
      this.fillForm();
    }
  }

  fillForm() {
    // this.integrationService.getIntegation(CHANNELS.WHATSAPP).subscribe(res => {
    //   const integation = res;
    //   this.integrationForm.setValue({
    //     firstName: this.profile.firstName,
    //     lastName: this.profile.lastName,
    //     phone: this.profile.phone,
    //     userId: this.profileId,
    //     imgSrc: null
    //   });
    // }, err => {
    //   console.log('no integation found');
    // });
  }

  private initForm(): void {
    this.integrationForm = this.formBuilder.group({
      channelName: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      certificate: [null, [Validators.required]]
    });
  }

  get formControls(): any {
    return this.integrationForm.controls;
  }

  submit() {
    this.submitted = true;
    console.log(this.integrationForm.value, this.integrationForm.invalid, this.integrationForm);
    if (!this.integrationForm.invalid) {
      this.integrationService.sendRequest(this.integrationForm.value).subscribe(res => {

      });
    }
  }

}
